import React from 'react';
import '../styles/About.css';
import perfumeImage from '../images/perfume-with-flowers.avif'; // Import your perfume image

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <div className="header">
          <h1>About Us</h1>
          <img src={perfumeImage} alt="Perfume with flowers" className="perfume-image" />
        </div>
        <p className="mission-statement">
          At <span className="company-name">Flenny Scent</span>, our mission is to create luxurious, high-quality perfumes that capture the essence of nature and evoke timeless elegance. We are dedicated to sustainability and ethical practices, ensuring that each scent is crafted with care for both people and the planet.
        </p>
        <p className="story">
          Founded in <span className="year">2024</span>, <span className="company-name">Flenny Scent</span> began as a small family-owned business with a passion for natural fragrances and a vision for sustainability. Over the years, we have grown into a beloved brand known for our commitment to quality, creativity, and environmental responsibility.
        </p>
        <p className="practices">
          <span className="heading">Our Practices</span><br />
          <strong>Natural Ingredients:</strong> We source the finest natural ingredients from around the world, ensuring that every perfume is crafted from pure, ethically sourced botanicals.<br />
          <strong>Sustainable Production:</strong> Our production methods are designed to minimize our environmental footprint. We use eco-friendly packaging, support fair trade practices, and are committed to reducing waste and conserving resources.<br />
          <strong>Innovation and Craftsmanship:</strong> Our perfumers combine traditional techniques with modern innovation to create unique and sophisticated fragrances. Each scent is meticulously crafted to provide a lasting impression and an unforgettable experience.
        </p>
        <p className="get-involved">
          <span className="heading">Get Involved</span><br />
          We invite you to join the <span className="company-name">Flenny Scent</span> community. Whether you’re exploring our perfume collections, visiting our boutique, participating in our workshops, or following us on social media, there are many ways to connect with us. Together, we can celebrate the art of fragrance and our shared commitment to sustainability.
        </p>
      </div>
    </div>
  );
};

export default About;
